export const branches = [
    {
        id:'1',
        name:'Доставка авто',
        title:'Доставка автовозом ЄС-УКРАЇНА',
        description:
                    <>
                        <li>Польщі, Німеччини, Нідерландів.</li>
                        <li>Портів Клайпеди, Гдині, Бремерхафена.</li>
                        <li>Норвегії, Данії, Швеції.</li>
                        <li>Литви, Латвії, Естонії.</li>
                        <li>Перевозимо авто на укр реєстрації.</li>
                        <li>Після поломки, чи ДТП</li>
                    </>,
        link:"/marshaltrans",
        img: '/autod_icons/1.svg',
    },
    {
        id:'2',
        name:'Евакуація авто',
        title:'Евакуація авто з України в ЄС',
        description:
                    <>
                        <li>Польщі, Німеччини, Нідерландів.</li>
                        <li>портів Клайпеди, Гдині, Бремерхафена.</li>
                        <li>Норвегії, Данії, Швеції.</li>
                        <li>Литви, Латвії, Естонії.</li>
                        <li>після поломки, чи ДТП</li>
                    </>,
        link:"/marshal-ua",
        img: '/autod_icons/2.svg',
    },
    {
        id:'3',
        name: 'Авто для ЗСУ',
        title:'Перевезення авто для ЗСУ:',
        description:
                    <>
                        <li>з Європи по листу від в/ч, або благодійного фонду;</li>
                        <li>по території України.</li>
                    </>,
        link:"/auto-delivery",
        img: '/autod_icons/3.svg',
    },

    {
        id:'4',
        name:'Перевезення по Волинській обл. та Луцьку',
        title:'Перевезення по Волинській обл. та Луцьку',
        description:
                    <>
                        <li>після ДТП;</li>
                        <li>на ремонт в СТО;</li>
                        <li>при поломці серед міста і на дорозі.</li>
                    </>,
        link:"/heavy-auto",
        img: '/autod_icons/1.svg',
    },
    {
        id:'5',
        name:'Перевезення по Україні',
        title:'Перевезення по Україні:',
        description:
                    <>
                        <li>адресно власнику;</li>
                        <li>після розмитнення;</li>
                        <li>між СТО.</li>
                    </>,
        link:"/heavy-auto",
        img: '/autod_icons/1.svg',
    },
]
export const blockContent = {
    title:`Зв'яжіться з нами`,
    phone: '+380502775676',
    callToAction:<>І ми обов'язково вирішимо ваше завдання</>,
    img:"/bg-icon.svg",
}

export const pageContent = {
    title:
        <>
           Доставка авто<br/>європа-україна<br/>україна-європа 
        </>,
    decription:'Доставляємо швидко, вчасно і надійно.',
    button:'Наберіть нас',
    img:"/autodelivery2.png",
    secondTitle:'Наші послуги',
    thirdTitle:'Щоб привезти ваше авто з Європи:',
    banner:"/banner_avto.jpg",
}
export const steps =[
    'Зателефонуйте або напишіть дані про авто і адресу.',
    'Ми відразу орієнтуємо по ціні і даті.',
    'Забираємо, проходимо кордон, привозимо за адресою.',
    'Оплачуєте, коли авто доставлене.'
]

export const contacts = {
    phone: '+380502775676',
    email:'marshaltrans89@gmail.com',
    adresse: <>офіс м.Луцьк <br/>Ковельська, 22</>,
    fb:'https://www.facebook.com/marshaltrans.manager',
    in:'https://www.facebook.com/marshaltrans.manager',
  }

export const MarshalNav = [
    {
        id:'1',
        name:'Про нас',
        alt:'about',
    },
    {
        id:'2',
        name:'Наші послуги',
        alt:'branches',
    },
    {
        id:'3',
        name:'Як ми працюємо',
        alt:'steps',
    },
    {
        id:'3',
        name:'Контакт',
        alt:'contact',
    },
]
