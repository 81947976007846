import React from "react";
import { Container, Box, Typography, Link, Grid, IconButton} from '@mui/material/';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const Footer = ()=>{
    const year = new Date().getFullYear();
    return (
      <Box 
        sx={{
            flexGrow:1,
            display: "flex",
            textAlign: 'center',
            width:'100%',
            justifyContent:"center",
            marginTop: "auto",
            p: 1,
        }}
      >
        <Typography variant="note" sx={{color: '#1d38698c'}}>ⓒ {year} Marshal Trans. All Rights Reserved.</Typography>
      </Box>
    );
}

export const FullFooter = ({phone, email, adresse, fb, linked})=>{
  return(
    <Container maxWidth="lg" minWidth="md">
      <Grid container justifyContent="left" mt={10} sx={{padding:'40px 0px', alignItems: 'center'}}>
            <Box sx={{ flexGrow: 1 }}>              
              <Link to="/" >
              <Box sx={{        
                  width:'100%',
                  height:'62px',
                  }}
              >
                  <img  className="logo" src='/logo-big.svg' alt='logo-marshal-trans'></img>     
              </Box>
              </Link>
            </Box>
              <Grid item xs={12} sm={4} md={4} lg={3} xl={3} sx={{ flexGrow:1, borderLeft: 1, borderColor: 'divider', padding:3}}>
                <Typography variant='note' sx={{fontWeight:'700'}}>
                <a href={`tel:${phone}`}>{phone}</a>
                <br/>
                <a href={`mailto:${email}`}>{email}</a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3} xl={3} sx={{ flexGrow:1, borderLeft: 1, borderColor: 'divider', padding:3}}>
                <Typography variant='note' sx={{fontWeight:'700'}}>
                  {adresse}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={1} md={1} lg={1} xl={1} sx={{ flexGrow:0, padding:1, display:'flex'}}>
                  <IconButton aria-label='facebook' color='primary' href={fb}>
                    <FacebookIcon sx={{width:'31px', height:'31px'}} />
                  </IconButton>
                  <IconButton aria-label='linkedin' color='primary' href={linked}>
                    <LinkedInIcon sx={{width:'31px', height:'31px'}} /> 
                  </IconButton>             
              </Grid>
      </Grid>
    </Container>
  )
}