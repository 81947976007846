import { Container, Box, Grid, Typography, IconButton} from "@mui/material"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import {Link, Navigate, useNavigate} from 'react-router-dom';
import { Header } from "../../components";

const BranchCard=({name, link, img, country})=>{
    const navigate = useNavigate();

    const handleclick =()=>{
        return navigate(link);
    }

    return (
      <Card sx={{ margin: 1}}>
        <CardActionArea onClick={handleclick}>
          <CardMedia
            component="img"
            height="230"
            image={img}
            alt={name}
          />
          <CardContent>
            <Box sx={{display:'flex'}}>
                <img src={`/${country}-flag.svg`}></img>
                <Typography variant="note" color="text.secondary">
                    {country==='ua'?'Ukraine company':'Poland company'}
                </Typography>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent:'space-between', alignItems: 'flex-end', padding:'10px 16px'}}>                      
                <Typography gutterBottom variant="h3" component="div" sx={{width:'250px'}}>
                    {name}
                </Typography>                
                <Link to={link}>
                    <IconButton color="primary" aria-label="add to shopping cart" sx={{marginBottom:'0.35em'}}>
                        <img src='/icons/arrow.svg'></img>
                    </IconButton>
                </Link>
            </CardActions>     
        </CardActionArea>
      </Card>
    );
}


const branches = [
    {
        id:'1',
        name:'Intermodal transport EU',
        link:"#",
        // link:"/intermodal",
        img: '/image-1.png',
        country: 'pl',
    },
    {
        id:'2',
        name:'Transport Ukraine trucks',
        link:"/marshal-ua",
        img: '/image-2.png',
        country: 'ua', 
    },
    {
        id:'3',
        name: <>Auto<br/>Delivery</>,
        link:"/auto-delivery",
        img: '/image-3.png',
        country: 'ua', 
    },

    {
        id:'4',
        name:'Heavy Autos sollutions',
        link:"/heavy-auto",
        img: '/image-4.png',
        country: 'ua', 
    },
]


export const Home = () =>{
    return(
        <div 
            style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '95vh', 
            backgroundImage:`url("/bg-icon.svg")`,
            backgroundPosition:'right top',
            backgroundRepeat: 'no-repeat',
            backgroundSize:'40%',
            }}
        >
        <Header />
            <Container>
                <Grid container justifyContent="left" mt={20} >
                    <Grid item xs={12} sm={10} md={6} lg={6} xl={6}> 
                    <Box
                        sx={{
                            margin: '3% auto',
                            width: '100%',
                            height: '100%',
                            textAlign: 'center',
                        }}
                        >                        
                        <Typography variant="h1" sx={{textTransform:'uppercase', textAlign:'left'}}>
                            European<br/>transport comapny
                        </Typography>
                    </Box>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Grid container justifyContent="center" mt={10} >
                    {branches.map((item, index)=>(            
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3} key={index} >
                            <BranchCard name={item.name} link={item.link} img={item.img} country={item.country} key={index} />          
                        </Grid>  
                    ))}
                </Grid>
            </Container>
        </div>

    )    
}

