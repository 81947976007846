import React from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styles from './Header.module.scss';
import { Container,Box, Typography } from '@mui/material/';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

export const Header = ({list})=>{
    const [anchorElNav, setAnchorElNav] = React.useState(null);


    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    return(
        <AppBar position="fixed" className={styles.header}>
            <Container maxWidth="lg" minWidth="md">
            <Toolbar disableGutters className={styles.inner}>
                {/* left*/}
                <Box sx={{ flexGrow: 0 }}>              
                    <Link to="#" >
                        <Box sx={{        
                            width:'100%',
                            height:'62px',
                            }}
                        >
                            <img className="logo" src='/logo-big.svg' alt='logo-marshal-trans'></img>     
                        </Box>
                    </Link>
                </Box>
                {!list?<></> :
                <>
                {/* navigation on desktop */}
                <Box className={styles.buttons}
                    sx={{
                    flexGrow: 0,
                    display: { xs: 'none', md: 'flex' }
                    }}
                >
                    {list.map((page, index) => (

                        <ScrollLink 
                            key={index} 
                            to={page.alt}
                            smooth={true} 
                            duration={500}
                            offset={-100}
                            style={{cursor: 'pointer'}}
                        >
                            <Typography variant="nav" marginRight={3}>
                                {page.name}
                            </Typography>
                        </ScrollLink>
                    ))}
                </Box>
                
                {/* burgermenu */}
                <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-name="menu-appbar"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="primary"
                    >
                    <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        {list.map((page, index) => (
                                <MenuItem key={index} onClick={handleCloseNavMenu}>
                                    <ScrollLink 
                                        key={index} 
                                        to={page.alt}
                                        smooth={true} 
                                        duration={500}
                                        offset={-100}
                                        style={{cursor: 'pointer'}}
                                        onClick={handleCloseNavMenu}
                                    >
                                        <Typography variant="nav" marginRight={2}>
                                            {page.name}
                                        </Typography>
                                    </ScrollLink>
                                </MenuItem>
                            ))
                        }
                    </Menu>
                </Box> 
                </>
                }
            </Toolbar>
            </Container>  
        </AppBar>
    )
}







 