import { createTheme } from "@mui/material/styles";
const montserrat = 'Montserrat, sans-serif';

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1D3869",
    },
  },
  typography: {
    fontFamily: montserrat, 
    h1:{
      fontWeight: 900,
      fontSize:'52px',
      '@media (max-width: 1025px)': {
        fontSize:'32px',
      },
      '@media (max-width: 768px)': {
        fontSize:'28px',
      },
    },
    h2:{
      fontWeight:900,
      fontSize:'36px',
      textTransform: 'uppercase',
      marginBottom:'10px',
      '@media (max-width: 1025px)': {
        fontSize:'26px',
      },
      '@media (max-width: 768px)': {
        fontSize:'24px',
        // color:'white',
      },
    },
    h3:{
      fontWeight:800,
      fontSize:'26px',
      marginBottom:'5px ',
      wordWrap: 'break-word',
      '@media (max-width: 768px)': {
        fontSize:'18px',
        // color:'white',
      },
    },
    h5:{
      fontWeight:700,
      fontSize:'22px',
      '@media (max-width: 768px)': {
        fontSize:'18px',
      },
    },
    nav:{
      fontWeight:700,
      textTransform: 'uppercase',
      fontSize:'18px',
      '@media (max-width: 768px)': {
        fontSize:'16px',
      },
    },
    p:{
      fontWeight:400,
      fontSize:'22px',
      lineHeight: '33px',
      color:'black',
      '@media (max-width: 768px)': {
        fontSize:'14px',
      },
    },
    subp:{
      fontWeight:400,
      fontSize:'24px',
      '@media (max-width: 1025px)': {
        fontSize:'20px',
      },
      '@media (max-width: 768px)': {
        fontSize:'14px',
      },
    },
    note:{
      fontWeight:400,
      fontSize:'18px',
      '@media (max-width: 768px)': {
        fontSize:'14px',
      },
    },
    button: {
      textTransform: "none",
      fontWeight: 400,
      fontSize:"14px",
      '@media (max-width: 768px)': {
        fontSize:'14px',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1399,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "15px", 
        },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            border:"none",
            padding:"8px 22px"
          },          
          props: { variant: "outlined" },
          style: {
            border:"0.5px solid #fff",
            border:"none",
            "&hover":{
              border:"0.5px solid #1D3869",
              padding:"8px 22px"
            }
          },
        },
      ],
    }
  },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            backgroundColor: "white",
            borderRadius: "15px",
          },
          "& .MuiInputBase-input": {
            backgroundColor: "#fff",
            color: "#1D3869",
            padding: "18px 37px",
            border: "1px solid #fcfcfc66",
            borderRadius: "15px"
          },
          "& .MuiInputBase-input:hover": {
            backgroundColor: "#fff",
            color: "#1D3869",
            border: "1px solid #fcfcfc66",
            borderRadius: "15px"
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
            borderColor: "#fcfcfc66",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: "15px",
            borderColor: "none",
            padding:"0px",
            top:'0px',            
          },
          "& .MuiOutlinedInput-notchedOutline legend": {
             display: "none",
            },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
        borderRadius: '15px',
        "&.MuiAccordion-root .MuiAccordion-rounded":{
          borderRadius: '15px',
        },
        "&.MuiAccordion-root:first-of-type":{
          borderRadius:'15px',  
        },
      },
      }
    },
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
});


