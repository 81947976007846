
import Container from "@mui/material/Container";
import { Routes, Route }from 'react-router-dom';
import { Footer } from "./components";
import { Home, AutoDelivery, HeavyAuto, MarshalUA } from "./pages";
import './App.css';

function App() {
  return (
    <>
      <Container
        maxWidth="xl"
        style={{
          flexGrow: 1, 
          padding: 0,
        }}
      >
        <Routes basename="/">        
          {/* <Route path="/" element={<Home />}/> */}
          <Route path="/" element={<AutoDelivery />}/>
          {/* <Route path="/auto-delivery" element={<AutoDelivery />}/>
          <Route path="/marshal-ua" element={<MarshalUA lang='ukr'/>}/>
          <Route path="/marshal-ua/eng" element={<MarshalUA lang='eng' />}/>
          <Route path="/heavy-auto" element={<HeavyAuto />}/> */}
        </Routes>
      </Container>    
      <Footer />      
    </>
  );
}

export default App;
