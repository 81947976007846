import React from "react";
import { Box, Container, Button, Typography, Grid} from '@mui/material/';

export const ContactUs = ({content, name, variant})=>{

    return (
        <Grid container  name={name}  
        sx={{
            flexGrow:1,
            backgroundColor: '#1d3869',
            color: 'white',
            width:'100%',
            height:'409px',
            marginTop: "auto",
            padding:'6em 2em ',
            justifyContent:"center",
        }}
        >
        {variant!=='secondary'?
          <Grid item lg={4} xl={4} sx={{display:'flex', justifyContent:"inherit", display: { xs: 'none', sm:'none', md:'none', lg: 'block', xl:'block'}}}>
            <img src={content.img} style={{width:'90%', height:'100%', transform: 'scaleX(-1)'}}></img>
          </Grid>
        :
        <Grid item lg={4} xl={4} sx={{display:'flex', justifyContent:"inherit", display: { xs: 'none', sm:'none', md:'none', lg: 'block', xl:'block'}}}>
            <img src={content.img} style={{width:'100%', height:'200px', transform: 'scaleX(-1)'}}></img>
          </Grid>
        }
        <Grid item xs={12} sm={10} md={6} lg={4} xl={4}         
          sx={{
          flexGrow:1,
          display: "flex column",
          textAlign: 'center',
          justifyContent:"inherit",
        }}>
          <Typography variant="h2">{content.title}</Typography>
          <Typography variant="subp">{content.callToAction}</Typography>
          <br/>
          <br/>
          <Typography variant="h3" color='white'><a style={{color:'inherit'}} href={`tel:${content.phone}`}>{content.phone}</a></Typography>
        </Grid>
        {variant!=='secondary'?
          <Grid item  md={6} lg={4} xl={4} sx={{display:'flex', justifyContent:"inherit", display: { xs: 'none', sm:'none', md:'none', lg: 'block', xl:'block'}}}>
            <img src={content.img} style={{width:'90%', height:'100%'}}></img>
          </Grid>
        :
        <Grid item  md={6} lg={4} xl={4} sx={{display:'flex', justifyContent:"inherit", display: { xs: 'none', sm:'none', md:'none', lg: 'block', xl:'block'}}}>
            <img src={content.img} style={{width:'100%', height:'200px'}}></img>
          </Grid>
        }        
        </Grid>
    );
}