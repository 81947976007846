
export const blockContent = {
    title:`Зв'яжіться з нами`,
    callToAction:'Some short text about company (what client have to know about you). Call to action!',
    img:"/intermodal.png",
}

export const pageContent = {
    title:<>Українська<br/>транспортна компанія</>,
    decription:'Some short text about company (what client have to know about you). Call to action!',
    button:'Contact us',
    img:"/intermodal.png",
    secondTitle:'we operate',
    // thirdTitle:'Щоб привезти ваше авто з Європи:',
    // banner:"/banner_avto.jpg",
}
export const steps =[
    {eng:'Зателефонуйте або напишіть дані про авто і адресу.', ukr:'Зателефонуйте або напишіть дані про авто і адресу.'},
    {eng:'Ми відразу орієнтуємо по ціні і даті.' , ukr:'Ми відразу орієнтуємо по ціні і даті.'},
    {eng:'Забираємо, проходимо кордон, привозимо за адресою.' , ukr:'Забираємо, проходимо кордон, привозимо за адресою.'},
    {eng:'Оплачуєте, коли авто доставлене.' , ukr:'Оплачуєте, коли авто доставлене.'}
]

export const contacts = {
    phone: '+48818847332',
    email:'office@marshaltrans.com',
    adresse: <>OFFICE Poland <br/> 20-703 Lublin, ul. Cisowa 9</>,
    fb:'https://www.facebook.com/marshaltrans.manager',
    in:'https://www.facebook.com/marshaltrans.manager',
  }

export const branches = [
    {
        id:'1',
        eng:'Standart Trucks',
        ukr:'Стандартні вантажівки',
        link:"#",
        img: '/image-1.png',
    },
    {
        id:'2',
        eng:'Mega Trucks',
        ukr:'Стандартні вантажівки',
        link:"#",
        img: '/image-2.png',
    },
    {
        id:'3',
        eng: 'Swap body trailers',
        ukr:'Стандартні вантажівки',
        link:"#",
        img: '/image-3.png',
    },

    {
        id:'4',
        eng:'Tandemtrucks with swap body system',
        ukr:'Стандартні вантажівки',
        link:"#",
        img: '/image-4.png',
    },
]

export const MarshalNav = [
    {
        id:'1',
        name:'Про нас',
        alt:'about',
    },
    {
        id:'2',
        name:'Наші послуги',
        alt:'branches',
    },
    {
        id:'3',
        name:'Як ми працюємо',
        alt:'steps',
    },
    {
        id:'3',
        name:'Контакт',
        alt:'contact',
    },
]
