export const branches = [
    {
        id:'1',
        name:'Доставка авто',
        title:'Доставка автовозом ЄВРОПА-УКРАЇНА з:',
        description:
                    <>
                        <li>Польщі, Німеччини, Нідерландів.</li>
                        <li>Портів Клайпеди, Гдині, Бремерхафена.</li>
                        <li>Норвегії, Данії, Швеції.</li>
                        <li>Литви, Латвії, Естонії.</li>
                        <li>Перевозимо авто на укр реєстрації.</li>
                        <li>Після поломки, чи ДТП</li>
                    </>,
        link:"/marshaltrans",
        img: '/autod_icons/1.svg',
    },
    {
        id:'2',
        name:'Евакуація авто',
        title:'Евакуація авто з:',
        description:
                    <>
                        <li>Польщі, Німеччини, Нідерландів.</li>
                        <li>портів Клайпеди, Гдині, Бремерхафена.</li>
                        <li>Норвегії, Данії, Швеції.</li>
                        <li>Литви, Латвії, Естонії.</li>
                        <li>після поломки, чи ДТП</li>
                    </>,
        link:"/marshal-ua",
        img: '/autod_icons/2.svg',
    },
    {
        id:'3',
        name: 'Авто для ЗСУ',
        title:'Перевезення авто для ЗСУ:',
        description:
                    <>
                        <li>з Європи по листу від в/ч, або благодійного фонду;</li>
                        <li>по території України.</li>
                    </>,
        link:"/auto-delivery",
        img: '/autod_icons/3.svg',
    },

    {
        id:'4',
        name:'Перевезення по Волинській обл. та Луцьку',
        title:'Доставка авто автовозом ЄВРОПА-УКРАЇНА',
        description:
                    <>
                        <li>після ДТП;</li>
                        <li>на ремонт в СТО;</li>
                        <li>при поломці серед міста і на дорозі.</li>
                    </>,
        link:"/heavy-auto",
        img: '/autod_icons/1.svg',
    },
    {
        id:'5',
        name:'Перевезення по Україні',
        title:'Перевезення по Україні:',
        description:
                    <>
                        <li>адресно власнику;</li>
                        <li>після розмитнення;</li>
                        <li>між СТО.</li>
                    </>,
        link:"/heavy-auto",
        img: '/autod_icons/1.svg',
    },
]
export const blockContent = {
    title:'Наберіть нас',
    callToAction:'Some short text about company (what client have to know about you). Call to action!',
    img:"/intermodal.png",
}

export const pageContent = {
    title:
        <>
           Телескопічні<br/>навантажувачі<br/>Manitou 
        </>,
    decription:'Some short text about company (what client have to know about you). Call to action!',
    button:'Наберіть нас',
    img:"/autodelivery.png",
    secondTitle:'Наші послуги',
    thirdTitle:'Щоб привезти ваше авто з Європи:',
    banner:"/banner_avto.jpg",
}
export const steps =[
    'Узгоджуємо деталі завдання, та проводимо розрахунок вартості робіт',
    'Ми швидко доставимо спецтехніку на об’єкт власним транспортом.',
    'Кваліфікований водій-оператор виконує поставлене завдання.',
    'Оплачуєте після виконання роботи'
]