import { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './AutoDelivery.module.scss';
import { Container, Box, Grid, Typography } from "@mui/material"
import { ContactUs, FullFooter, Header} from '../../components';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { SvgIcon, branches, blockContent, pageContent, steps, contacts, MarshalNav} from './components/';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as ScrollLink } from 'react-scroll';


export const AutoDelivery = () =>{

    useEffect(() => {
        document.title = 'Marshal Trans — Доставка авто з Європи. Швидко, вчасно і надійно. {contacts.phone}';
      }, [])
      
    return(
        <>
        <Header list={MarshalNav} />
        <div 
            style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '95vh', 
            backgroundImage:`url("/bg-icon2.svg")`,
            backgroundPosition:'right top',
            backgroundRepeat: 'no-repeat',
            backgroundSize:'30%',
            }}
        >
            <Container name={MarshalNav[0].alt} sx={{marginTop:'60px'}}>
                <Grid container justifyContent="left" mt={10} mb={10} sx={{maxHeight:'652px'}}>
                    <Grid item xs={12} sm={10} md={5} lg={5} xl={5} order={{ xs: 2, sm: 2, md: 1, lg: 1, xl:1  }}> 
                        <Box
                            sx={{
                                margin: '3% auto',
                                width: '100%',
                                height: '100%',
                                textAlign: 'left',
                            }}
                            >                        
                            <Typography variant="h1" sx={{textTransform:'uppercase'}}>
                                {pageContent.title}
                            </Typography>
                            <br/>
                            <Typography variant="subp" >
                                {pageContent.decription}
                            </Typography>
                            <br/>
                            <br/>
                            <ScrollLink 
                            to='contact'
                            smooth={true} 
                            duration={500}
                            offset={-100}
                            style={{cursor: 'pointer'}}
                        >
                            <Button variant='contained' color='error' size="large">{pageContent.button}</Button>
                        </ScrollLink>       
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={10} md={7} lg={7} xl={7} order={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }} >
                        <img className={styles.mainImage} src={pageContent.img}></img>                       
                    </Grid>
                </Grid>
            </Container>
            <Container name={MarshalNav[1].alt} sx={{marginTop:10}} >
                <Typography variant='h2'>{pageContent.secondTitle}</Typography>
                <BranchesTabs tabs={branches}/>
            </Container>
            <Container name={MarshalNav[2].alt} sx={{marginTop:10, marginBottom:10}}>
                <Box
                    styleName={styles.banner}
                    sx={{
                        backgroundImage: `url(${pageContent.banner})`,
                        // backgroundSize: '100%', 
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        padding: '139px 111px',
                        borderRadius:'15px',
                        '@media (max-width: 768px)': {
                            padding: '139px 47px',
                            },      
                    }}
                >
                 <Typography variant='h2'
                     color='white'
                    sx={{
                        fontSize:'50px',
                        maxWidth:'723px',
                            '@media (max-width: 1025px)': {
                            fontSize:'30px',
                            },
                    }}
                  >
                  {pageContent.thirdTitle}
                  </Typography>
                </Box>
                <Grid container justifyContent="left" mt={10}>
                    {steps.map((item, index)=>(                        
                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3} key={index} sx={{ borderRight: index !==3 ? 1:'none', borderColor: 'divider', padding:3}}>
                            <Typography key={index} variant='h1'>0{index+1}</Typography>
                            <Typography key={index} variant='p' >{item}</Typography>
                        </Grid>
                    ))}
                </Grid>         
            </Container>
            <ContactUs name={MarshalNav[3].alt} content={blockContent} variant='secondary'/>
        </div>
        <FullFooter phone={contacts.phone} email={contacts.email} adresse={contacts.adresse} fb={contacts.fb} linked={contacts.in} />
    </>
    )    
}

function CustomTabPanel({ description, title, index, value }) {
    return (
        <div
            className='fade-in-animation'
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography variant='h3'>{title}</Typography>
                    <Typography variant='p'>{description}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    description: PropTypes.node,
    title: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const BranchesTabs = ({ tabs }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    

    const handleClick = (index) => {
        setActiveTabIndex(index);
    };

    return (
        <>
        {/* desktop */}
        <Grid container justifyContent="space-between" sx={{ display: { xs: 'none', sm:'none', md:'flex', lg: 'flex', xl:'flex'}}}>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                {tabs.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => handleClick(index)}
                        className={`tab-item ${activeTabIndex === index ? 'fade-in backgroundColorTransition' : ''}`}
                        style={{
                            backgroundColor:'white',
                            maxHeight: '87px',
                            width: '100%',
                            maxWidth: 'none',
                            padding: '18px 25px',
                            marginBottom: '1em',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            textWrap: 'balance',
                            borderRadius:'15px',
                            cursor: 'pointer',
                         }}
                    >
                        <SvgIcon color={activeTabIndex === index ? 'white' : '#1D3869'} index={index} />
                        <Typography
                            variant='subp'
                            sx={{
                                borderRadius: '15px',
                                fontSize: '16px',
                                textTransform: 'uppercase',
                                fontWeight: '700',
                                textAlign: 'left',
                                marginLeft: '20px',
                                marginRight: 'auto',
                                color: activeTabIndex === index ? 'white' :'#1D3869' ,
                            }}
                        >
                            {item.name}
                        </Typography>
                        <AddRoundedIcon sx={{color: activeTabIndex === index ? 'white' :'#1D3869'}} />
                    </div>
                ))}
            </Grid>           
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <img src='/icons/v-filled.svg' ></img> 
                {tabs.map((item, index) => (
                    <CustomTabPanel key={index} value={activeTabIndex} index={index} title={item.title} description={item.description} />
                ))}
            </Grid>
        </Grid>
        {/* mobile acordeon */}
        <Grid item sx={{ display: { xs: 'block', sm:'block', md:'none', lg: 'none', xl:'none'}}}>
            {tabs.map((item, index) => (
            <Accordion key={index} elevation={0}
            sx={{
                marginBottom:'5px',
                '&:before': {
                    display: 'none',
                }}
            }
            >
                <AccordionSummary
                    onClick={() => handleClick(index)}
                    className={`tab-item ${activeTabIndex === index ? 'fade-in backgroundColorTransition' : ''}`}
                    style={{
                            backgroundColor:'white',
                            maxHeight: '87px',
                            width: '100%',
                            maxWidth: 'none',
                            padding: '18px 25px',                    
                            textWrap: 'balance',
                            borderRadius:'15px',
                            cursor: 'pointer',
                         }}
                    expandIcon={<ExpandMoreIcon  sx={{color: activeTabIndex === index ? 'white' :'#1D3869'}}/>}
                    aria-controls="panel1a-content"
                    key={index}
                >
                    <SvgIcon color={activeTabIndex === index ? 'white' : '#1D3869'} index={index} />
                        <Typography
                            variant='subp'
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '16px',
                                textTransform: 'uppercase',
                                fontWeight: '700',
                                textAlign: 'left',
                                marginLeft: '20px',
                                marginRight: 'auto',
                                color: activeTabIndex === index ? 'white' :'#1D3869' ,
                            }}
                        >
                            {item.name}
                        </Typography>
                </AccordionSummary>
                <AccordionDetails key={index}>
                    <Typography variant='h3'>{item.title}</Typography>
                    <Typography variant='p'>{item.description}</Typography>
                </AccordionDetails>
            </Accordion>
            ))}
        </Grid>
        </>

    );
};






